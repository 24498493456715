<script>
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'

export default {
    components: { Layout, PageHeader },
    data() {
        return {
            nameGroup: '',
            lastName: '',
            stContact1: '',
            position: '',
            email: '',
            officePhone: '',
            mobilePhone: '',
            fax: '',
            notes: '',
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            city: '',
            state: '',
            postCode: '',
            sameBilling: false
        }
    },
    methods: {
        showValues() {
            if(this.sameBilling) {
                this.address3 = '',
                this.address4 = ''
            }
            return ([
                this.nameGroup,
                this.lastName,
                this.stContact1,
                this.position,
                this.email,
                this.officePhone,
                this.mobilePhone,
                this.fax,
                this.notes,
                this.address1,
                this.address2,
                this.address3,
                this.address4,
                this.city,
                this.state,
                this.postCode,
                this.sameBilling
            ])
        },
        handleSubmit() {
            this.showValues()

        }
    }, mounted() {}

}
</script>
    
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                    type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Company info</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                    type="button" role="tab" aria-controls="profile-tab-pane"
                    aria-selected="false">Distributors</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                    type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Accounting</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane"
                    type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false">Settings</button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                tabindex="0">
                <p class="fw-bold text-danger mt-3">*Required</p>
                <form action="" class="shadow-sm p-3 mb-5 bg-body-tertiary rounded">
                    <div class="row gap-4">
                        <div class="col-4">
                            <div class="mb-3 row">
                                <label for="nameOfCompany" class="col-sm-2 col-form-label">Name Of Company</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="nameOfCompany" required v-model="nameGroup">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="1st Contact Person" class="col-sm-2 col-form-label">1st Contact
                                    Person</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="1st Contact Person"
                                    required
                                        v-model="stContact1">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Position" class="col-sm-2 col-form-label">Position</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="Position" v-model="position">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Email" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="email" class="form-control" required id="Email" v-model="email">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Office Phone" class="col-sm-2 col-form-label">Office Phone</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="OfficePhone" v-model="officePhone">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Mobile Phone" class="col-sm-2 col-form-label">Mobile Phone</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="MobilePhone" v-model="mobilePhone">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Fax" class="col-sm-2 col-form-label">Fax</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="Fax" v-model="fax">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Fax" class="col-sm-2 col-form-label">Notes</label>
                                <div class="col-sm-10">
                                    <textarea class="form-control" name="" id="" cols="30" rows="10"
                                        v-model="notes"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="col-4">
                            <p class="fw-bold">Billing Address</p>

                            <div class="mb-3 row">
                                <label for="Address1" class="col-sm-2 col-form-label">Address 1</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="Address1" required v-model="address1">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Address 2" class="col-sm-2 col-form-label">Address 2</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="Address 2" v-model="address2">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="City" class="col-sm-2 col-form-label">City</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" required id="City" v-model="city">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="State" class="col-sm-2 col-form-label">State</label>
                                <div class="col-sm-10">
                                    <select name="state" id="state" class="form-control" required v-model="state">
                                        <option value="">Select State</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="mb-3 row">
                                <label for="Country" class="col-sm-2 col-form-label">Country</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="Country">
                                </div>
                            </div>-->
                            <div class="mb-3 row">
                                <label for="Post Code" class="col-sm-2 col-form-label">Post Code</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" required id="postCode" v-model="postCode">
                                </div>
                            </div>
                            <div class="d-flex">
                                <p class="fw-bold my-1">Shipping Address</p>
                                <input type="checkbox" name="" id="" class="my-1 mx-3" v-model="sameBilling"> same as
                                billing
                            </div>
                            <div class="mb-3 row">
                                <label for="Address 1" class="col-sm-2 col-form-label">Address 1</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" required id="Address 1"
                                        :disabled="sameBilling === true" v-model="address3" v-if="!sameBilling">
                                        <input type="text" class="form-control" id="Address 1"
                                        :disabled="sameBilling === true" :value="address1" v-if="sameBilling">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="Address 1" class="col-sm-2 col-form-label">Address 2</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="Address 1"
                                        :disabled="sameBilling === true" required v-model="address4" v-if="!sameBilling">
                                        <input type="text" class="form-control" id="Address 1"
                                        :disabled="sameBilling === true" :value="address2" v-if="sameBilling">
                                </div>
                            </div>
                        </div>
                        {{ showValues() }}
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn  border-dark mx-3 px-4">Cancel</button>
                        <button class="btn border-dark mx-3  px-4" @click.prevent="handleSubmit">Save</button>
                    </div>
                </form>

            </div>

            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <h3>PARKER MANAGEMENT - Doctors / Distributors Information</h3>
                <div class="d-flex justify-content-evenly">
                    <div class="col-5 d-flex">
                        <div class="col-5">
                            <input type="text" name="search_company" id="" class="form-control"
                                placeholder="Name Or ID">

                        </div>
                        <div class="col-3 mx-2">
                            <input type="checkbox" name="includeArchived" id="includeArchived">
                            <label class="mx-1">Include archived</label>

                        </div>
                    </div>

                    <div class="col-3">
                        <input type="checkbox" class="btn-check" id="btn-check-2" checked autocomplete="off">
                        <label class="btn btn-primary" for="btn-check-2">Show All</label>
                        <!--<input type="checkbox" class="btn-check" id="btn-check-2" checked autocomplete="off">
                        <label class="btn btn-primary mx-1" for="btn-check-2">Import</label>-->
                        <p>1024 Distributors</p>
                    </div>


                </div>


                <table class="table mt-4">
                    <thead>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                Josh Baker
                            </th>
                            <th>ID:1426</th>
                            <th>JoshBaker@hotmail.com</th>
                            <th>1423 Clients</th>
                        </tr>

                        <tr>
                            <th>
                                Josh Baker
                            </th>
                            <th>ID:1426</th>
                            <th>JoshBaker@hotmail.com</th>
                            <th>1423 Clients</th>

                        </tr>

                        <tr>
                            <th>
                                Josh Baker
                            </th>
                            <th>ID:1426</th>
                            <th>JoshBaker@hotmail.com</th>
                            <th>1423 Clients</th>

                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                <h3 class="mb-4">PARKER MANAGEMENT</h3>


                <div class="col-2 d-flex justify-content-evenly mb-4">
                    <p class="fw-bolder">Affiliate Percent</p>
                    <input class="form-control" type="text" name="porcent" id="porcent" value="2.5%">
                </div>
                <p class="fw-bold">Distributor</p>

                <div class="">
                    <ul>
                        <li class="listDis fw-bold"><a href="#">Oct. 31,2020</a></li>
                        <li class="listDis fw-bold">
                            <a href="#">Sept. 30,2020</a>
                        </li>
                        <li class="listDis fw-bold">
                            <a href="#">Aug,31,2020</a>
                        </li>
                        <li class="listDis fw-bold">
                            <a href="#">July.31, 2020</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab"
                tabindex="0">Settings</div>
        </div>

    </Layout>
</template>

<style>
.listDis {
    list-style: none;
    margin-bottom: 10px;
}
</style>
